:root {
  --primary: #020617;
  --secondary: #001129;
  --basic: #75E6DA;
  --common: #D4F1F4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* html styles */
html {
  scroll-behavior: smooth;
}

/* body styles */
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  background-color: var(--secondary)
}

/* text selection style */
::selection {
  background-color: aquamarine;
  color: #102a43;
}

/* Loading animation */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

/* logo in navbar */
.showMenuNav {
  background-image: url("./design/logo-half.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  background-color: #001129;
}

/* satellite svg animation */
.satellite {
  position: fixed;
  width: 30%;
  height: 30%;
  bottom: 0px;
  right: 0;
  overflow: visible;
}

/* input fields animation */
.inputBox {
  position: relative;
}

.inputBox input, textarea {
  border: #8d286f 1px solid;
  background-color: #001129;
  outline: none;
  color: white;
  padding: 20px;
  font-size: 1em;
  cursor: pointer;
  width: 300px;
  box-sizing: border-box;
  transition: border-color 0.5s, background-color 0.5s, transform 0.2s;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  font-size: 1em;
  text-transform: uppercase;
  pointer-events: none;
  color: #00A0B2;
    transition: transform 0.5s, font-size 0.5s, padding 0.5s,
    background-color 0.5s, border-left-color 0.5s, border-right-color 0.5s,
    letter-spacing 0.5s, color 0.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  transform: translateX(10px) translateY(-7px);
  font-size: 0.75em;
  padding: 0 10px;
  background: #001129;
  border-left: aquamarine 1px solid;
  border-right: aquamarine 1px solid;
  letter-spacing: 0.2em;
  color: aquamarine;
  transition: 0.2s;
}

.inputBox textarea:focus ~ span {
    transform: translateX(10px) translateY(-7px);
    font-size: 0.75em;
    padding: 0 10px;
    background: aquamarine;
    border-radius: 2px;
    color: #102a43;
    letter-spacing: 0.2em;
    top: 0;
      transition: transform 0.2s, font-size 0.2s, padding 0.2s,
    background-color 0.2s, color 0.2s;
}


/* Project imgs scroll on hover */
.screen {
  width: 90%;
  height: 450px;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  border: #8d286f solid 2px;
  border-radius: 20px;
}

.screen img {
  top: 0%;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: top 1s;
  -moz-transition: top 1s;
  -ms-transition: top 1s;
  -o-transition: top 1s;
  transition: top 1s;
}

.screen:hover img {
  top: -20%;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

/* footer icons animation */
.footer-list {
  position: fixed;
  bottom: 15px;
  left: 30%;
  right: 30%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

@media (max-width: 1000px) {
  .footer-list {
    position: fixed;
    bottom: 15px;
    left: 10px;
    right: 10px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
}

.footer-list ul {
    display: flex;
}
.footer-list ul li {
  position: relative;
  list-style: none;
  display: flex;
  width: 100px;
  height: 70px;
  z-index: 1;
}

.footer-list ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.footer-list ul li a .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: white;
}

.footer-list ul li a .icon:hover {
  cursor: pointer;
}

.footer-list ul li.active a .icon {
  transform: translateY(-35px);
  color: white;
}

.footer-list ul li a .text {
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}

.footer-list ul li.active a .text {
  opacity: 1;
  transform: translateY(10px);
}

.indicator {
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background-color: #00A0B2;
  border-radius: 50%;
  border: 6px solid #001129;
  transition: 0.5s;
}

.indicator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -23px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 0px -10px 0 0 #001129;
}

.indicator::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -23px;
  width: 20px;
  height: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0px -10px 0 0 #001129;
}

.footer-list ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(15px * 1));
} 

.footer-list ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(115px * 1));
} 

.footer-list ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(107px * 2));
} 

.footer-list ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(105px * 3));
}


